import "./index.css";
import move from "../../Images/header/move.svg";
import edit from "../../Images/header/edit.svg";
import DeleteIcon from "../../Images/header/newIcons/DeleteIcon.svg";


import ButtonFieldInput from "../uiComponents/buttonField/Button";
// import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldInput from "../uiComponents/FormFields/TextFieldInput";
import selected from "../../Images/header/move-selected.svg";
import { ClickAwayListener } from "@mui/base";
import lock from "../../Images/Icons/lock.svg";
import unlock from "../../Images/Icons/unlock.svg";
import ConfirmationModal from "../uiComponents/ConfirmationModal";
import CancelMagicLinkRequest from "../uiComponents/magicLinkModal/CancelMagicLinkRequest";
import ConfirmMagicLinkModal from "../uiComponents/magicLinkModal/ConfirmMagicLinkModal";
import { Tooltip } from "@mui/material";
import text from "../en_US.json";
import CheckExistUserModal from "../uiComponents/signupFlowModals/CheckExistUserModal";
import OtpLoginModal from "../uiComponents/signupFlowModals/OtpLoginModal";
import PasswordLoginModal from "../uiComponents/signupFlowModals/PasswordLoginModal";
import { useState } from "react";

import { LuZoomIn } from "react-icons/lu";
import { LuZoomOut } from "react-icons/lu";
import { LuUndo2 } from "react-icons/lu";
import { LuRedo2 } from "react-icons/lu";
import { TbZoomReset } from "react-icons/tb";
import { LiaCubeSolid } from "react-icons/lia";
import { HiCube } from "react-icons/hi2";
import { AiOutlineDelete } from "react-icons/ai";


const Header = (props: any) => {
    const [isSelected, setIsSelected] = useState(false);

    const {
        lockCanvas,
        lockOrUnlockCanvas,
        saveNetInfo,
        saveCanvasData,
        cancelNetCreation,
        showCancel,
        btnLoader,
        checkFunc,
        addRope,
        addSupport,
        pannelData,
        addRopeSupport,
        loading,
        enableRopeSupport,
        editorMemo,
        showDeleteLine,
        toggleDeleteMode,
        deleteMode
    } = props;

    const handleAddSupportClick = () => {
        setIsSelected(!isSelected);
        props.addSupport(!isSelected);
    }

    const handelDelete = () => {
        setIsSelected(false);
        props.handleDelete()
    }



    const menuArray = [
        {
            title: text?.zoomIn,
            icon: <LuZoomIn />,
            onClick: (e: any) => props.handleZoom(e, "zoomin")
        },
        {
            title: text?.zoomOut,
            icon: <LuZoomOut />,
            onClick: (e: any) => props.handleZoom(e, "zoomout")
        },
        {
            title: text.resetZoom,
            icon: <TbZoomReset />,
            onClick: props.resetZoom
        },
        {
            title: text?.undo,
            icon: <LuUndo2 />,
            onClick: props.handleUndo
        },
        {
            title: text?.redo,
            icon: <LuRedo2 />,
            onClick: props.handleRedo
        },
    ]

    return (
        <div className="relative w-full flex justify-between items-center !z-30 bg-white py-2.5 px-5 shadow-xl">

            {/** header left part */}
            <div className="flex items-center gap-1.5 2xl:gap-4">

                {/** header left part */}

                {menuArray.map((item, index) => (
                    <Tooltip key={index} title={item.title}>
                        <div
                            onClick={item.onClick}
                            className={`text-[24px] hover:bg-[#1473E6]/[0.1] active:text-[#1473E6] w-12 h-12 flex justify-center items-center rounded-full
                               ${props.sidePanelType === "" ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}>
                            {item.icon}
                        </div>
                    </Tooltip>
                ))}

                {/* /// Add Support Icon Click */}
                {(props.showAddSupport) &&
                    <Tooltip title={props?.text?.addSupport}>
                        <div
                            onClick={handleAddSupportClick}
                            className={`text-[24px] hover:bg-[#1473E6]/[0.1] active:text-[#1473E6] w-12 h-12 flex justify-center items-center rounded-full
                                 cursor-pointer ${isSelected ? "hover:bg-[#1473E6]/[0.1]" : ""}`}>
                            {isSelected ? <HiCube className="text-[20px] text-[#1473E6]" /> : <LiaCubeSolid />}
                        </div>
                    </Tooltip>
                }

                {props.showDelete &&
                    <Tooltip title="Delete">
                        <div
                            onClick={handelDelete}
                            className={`text-[24px] hover:bg-[#1473E6]/[0.1] active:text-[#1473E6] w-12 h-12 flex justify-center items-center rounded-full cursor-pointer`}>
                            <AiOutlineDelete />
                        </div>
                    </Tooltip>
                }



                {/* {props?.selectedHeaderHelpContent && (<Tooltip title={text.span}>
                    <div id='redo' className={props.sidePanelType === '' ? 'disable-img' : 'header-img'}
                        onClick={()=> setOpenModal(true)}>
                        <img src={helpicon} alt="next" width={26} height={26} />
                    </div>
                </Tooltip>)} */}






                {props.selectionType === "net" && (
                    <>
                        {props.selected ? (
                            <div
                                className={
                                    props.sidePanelType === "" ? "disable-img" : "header-img"
                                }
                                onClick={() => props.enableDisableClickThrough()}
                            >
                                <img src={selected} alt="selected" />
                            </div>
                        ) : (
                            <div
                                className={
                                    props.sidePanelType === "" ? "disable-img" : "header-img"
                                }
                                onClick={() => props.enableDisableClickThrough()}
                            >
                                <img src={move} alt="move" />
                            </div>
                        )}
                    </>
                )}
                {/* {props.drawingMode && (
          <p
            style={{ fontWeight: 500, color: "red" }}
            onClick={() => props.closeDrawingMode()}
          >
            Stop Drawing
          </p>
        )}
        {props.drawingMode && (
          <p
            style={{ fontWeight: 500, color: "red" }}
            onClick={() => props.movePoints()}
          >
            {props.move ? "Stop Moving Points" : "Move Points"}
          </p>
        )} */}
            </div>

            {/** header mid part */}
            {props.handleOutclickEdit && <ClickAwayListener onClickAway={props.handleOutclickEdit}>
                <div className="header-mid cursor-pointer">
                    {props.openEdit ? (
                        <TextFieldInput
                            // style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                            textnewclass={`bg-transparent rounded-[8px] addaddressInputFieldsGlobal`}
                            placeholder={`Quote Name`}
                            textinputname={`quoteName`}
                            id={`quoteName`}
                            type={`text`}
                            onChange={props.onChange}
                            onBlur={props.handleOpenEdit}
                            value={
                                props.quoteData?.quoteName ? props.quoteData?.quoteName : ""
                            }
                            error={false}
                            errText={""}
                            variant={"standard"}
                        />
                    ) : (
                        <p className="text-[15px] xl:text-[18px] text-blue-600" onClick={props.handleOpenEdit}>
                            {props.quoteData?.quoteName ? props.quoteData?.quoteName : ""}
                        </p>
                    )}
                    <div className="header-mid-img">
                        <img
                            src={edit}
                            alt="edit"
                            onClick={props.handleOpenEdit}
                            width={22}
                            height={22}
                        />
                    </div>
                </div>
            </ClickAwayListener>}

            {/** header right part */}
            <div className="header-right">

                {props.assemblyEditor && addRope &&
                    <div className="rounded-lg">
                        <ButtonFieldInput
                            name="Add Rope Support"
                            buttonextracls={`!w-28 !text-white !capitalize !text-sm !bg-[#1473E6] hover:bg-[#1473E6] !py-2 !px-4 !rounded-3xl !font-bold font-[SansRegular] duration-500 cursor-pointer !w-full`}
                            handleButton={() => { setIsSelected(false); addRopeSupport() }}
                            disabled={!enableRopeSupport}
                        />
                    </div>}
                {props.assemblyEditor ? null :
                    <div className="border-[1px] border-[#c2c1c1] rounded-lg">
                        <ButtonFieldInput
                            name="Editor tour"
                            buttonextracls={`!2xl:w-15 !capitalize !text-[12px] !2xl:text-sm !py-2 !2xl:py-3 !px-4 !xl:px-6 !font-bold font-[SansRegular] duration-500 cursor-pointer`}
                            handleButton={() => {
                                props.handleDriver();
                            }}
                        //  disabled = {props.netData?.length === 0}
                        />
                    </div>}
                <div className="border-[1.5px] border-[#c2c1c1] rounded-lg delete-all-nets">
                    <ButtonFieldInput
                        name={props.assemblyEditor ? "Clear" : "Delete All Nets"}
                        buttonextracls={`!2xl:w-15 !capitalize !text-black !text-[12px] !2xl:text-sm !py-2 !2xl:py-3 !px-4 !2xl:px-6 !font-bold font-[SansRegular] duration-500 cursor-pointer`}
                        handleButton={() => props.handleResetModal()}
                    //  disabled = {props.netData?.length === 0}
                    />
                </div>
                {showDeleteLine && <div className="border-[1.5px] border-[#c2c1c1] rounded-lg delete-all-nets">
                    <ButtonFieldInput
                        name={deleteMode ? "Close Delete" : "Delete Wire"}
                        buttonextracls={`!2xl:w-15 !capitalize !text-black !text-[12px] !2xl:text-sm !py-2 !2xl:py-3 !px-4 !2xl:px-6 !font-bold font-[SansRegular] duration-500 cursor-pointer`}
                        handleButton={() => toggleDeleteMode(!deleteMode)}
                        img={DeleteIcon}
                    />
                </div>}
                {showCancel && editorMemo && editorMemo.getObjects()?.length && (
                    <div className="border-[1.5px] border-[#c2c1c1] rounded-lg">
                        <ButtonFieldInput
                            name={"Delete Current Net"}
                            buttonextracls={`!2xl:w-15 !capitalize !text-black !text-[12px] !2xl:text-sm !py-2 !2xl:py-3 !px-4 !2xl:px-6 !font-bold font-[SansRegular] duration-500 cursor-pointer`}
                            // handleButton={cancelNetCreation}
                            handleButton={() => props.handlePanelCancelModal()}
                        />
                    </div>
                )}
                {editorMemo && ((!props.assemblyEditor && editorMemo.getObjects('polygon')?.length > 0) || (editorMemo.getObjects('group')?.length > 0 && editorMemo.getObjects('line')?.length > 0) || (editorMemo.getObjects('polygon')?.length > 0 && editorMemo.getObjects('polyline')?.length > 0)) &&
                    <div className="border-[1px] border-[#c2c1c1] rounded-lg lock-unlock">
                        <ButtonFieldInput
                            name={lockCanvas ? "Hide Dimensions" : "Display Dimensions"}
                            buttonextracls={`!2xl:w-15 !capitalize !text-black !text-[12px] !2xl:text-sm !py-2 !2xl:py-3 !px-4 !2xl:px-6 !font-bold font-[SansRegular] duration-500 cursor-pointerl`}
                            handleButton={lockOrUnlockCanvas}
                            img={lockCanvas ? unlock : lock}
                        />
                    </div>}
                {props.assemblyEditor ?
                    <>
                        {pannelData && pannelData?.type === 'rope_support' &&
                            <div className="save-next-quote">
                                <ButtonFieldInput
                                    name={"Save"}
                                    buttonextracls={`!w-28 !text-white !capitalize !text-sm !bg-[#1473E6] hover:bg-[#1473E6] !py-2 !px-4 !rounded-3xl !font-bold font-[SansRegular] duration-500 cursor-pointer !w-full`}
                                    handleButton={() => { setIsSelected(false); props.saveData(); }}
                                    loading={props.loader}
                                    disabled={props.loader || loading || addRope}
                                />
                            </div>}
                        <div className="save-next-quote">
                            <ButtonFieldInput
                                name={"Save & Continue"}
                                buttonextracls={`!w-28 !text-white !capitalize !text-sm !bg-[#1473E6] hover:bg-[#1473E6] !py-2 !px-4 !rounded-3xl !font-bold font-[SansRegular] duration-500 cursor-pointer !w-full`}
                                handleButton={() => { setIsSelected(false); props.saveAndContinue('continue'); }}
                                loading={loading}
                                disabled={props.loader || loading || addRope || (pannelData?.type !== 'rope_support' && !editorMemo?.getObjects().filter((obj: any) => obj?.objectType === "support" && obj?.addedBy === 'admin').length)}

                            />
                        </div>
                    </> :
                    <div className="!rounded-xl">
                        <ButtonFieldInput
                            name={props.showAddNet ? "Save Net" : "Next"}
                            buttonextracls={`!bg-[#1473E6] hover:bg-[#1473E6] !2xl:w-15 !capitalize !text-white !text-[12px] !2xl:text-sm !py-2 !2xl:py-3 !px-4 !2xl:px-6 !font-bold font-[SansRegular] duration-500 cursor-pointer `}
                            handleButton={props.showAddNet ? checkFunc : saveCanvasData}
                            loading={props.loader || btnLoader}
                            disabled={props.loader || btnLoader}
                        />
                    </div>}
            </div>
            {props.openSupportConfirmModal && (
                <ConfirmationModal
                    modalText="Support check"
                    open={props.openSupportConfirmModal}
                    handleClose={props.handleSaveCanvasData}
                    handleChange={props.onConfirmHandler}
                    DilogContent={`Proceed without Supports?`}
                    ConfirmBtn={"Yes"}
                    netData={props.netData}
                    loading={props.loader}
                />
            )}

            {props.showResetModal === true && (
                <ConfirmationModal
                    modalText={props.assemblyEditor ? "Delete Current Design" : "Delete All Nets"}
                    open={props.showResetModal}
                    handleClose={props.handleResetModal}
                    handleChange={() => { setIsSelected(false); props.clearCanvas(); }}
                    DilogContent={props.assemblyEditor ? "Are you sure you want to cancel current design?" : `Are you sure you want to delete all nets?`}
                    ConfirmBtn={"Yes"}
                    netData={props.netData}
                    loading={props.loader}
                />
            )}
            {props.cancelPanelModal === true && (
                <ConfirmationModal
                    modalText="Delete Current Net"
                    open={props.cancelPanelModal}
                    handleClose={props.handlePanelCancelModal}
                    handleChange={() => cancelNetCreation()}
                    DilogContent={`Are you sure you want to delete current net?`}
                    ConfirmBtn={"Yes"}
                    netData={props.netData}
                    loading={props.loader}
                />
            )}
            {props.showSoftAlert === true && (
                <ConfirmationModal
                    modalText={`Proceed without ${pannelData?.type == "rope_support" ? "Wire" : "Rope"}?`}
                    open={props.showSoftAlert}
                    handleClose={props.closeSoftModal}
                    handleChange={() => props.saveData('continue')}
                    DilogContent={`Are you sure you want to continue without adding any ${pannelData?.type == "rope_support" ? "Wire" : "Rope"} Support?`}
                    ConfirmBtn={"Yes"}
                    netData={props.netData}
                    loading={props.loader}
                />
            )}
            {/* {props?.openSignupForm ? (
                <MagicLinkModal
                    open={props.openSignupForm}
                    onClose={props.closeSignupModal}
                    magicLinkForm={props.magicLinkForm}
                    magicFormBtnLoader={props.magicFormBtnLoader}
                    handleCancelMagiModal={props.handleCancelMagiModal}
                    isCancelBtn={true}
                    title={`Congratulations`}
                    description={`You have successfully created net(s), in order to save for future use, kindly enter your email. 
                        We will send you email with account details. if you do not want to save  in your account you can cancel this request.`}
                />
            ) : null} */}
            {props?.openSignupForm ? (
                <CheckExistUserModal
                    open={props.openSignupForm}
                    onClose={props.closeSignupModal}
                    emailVerifyForm={props.emailVerifyForm}
                    loader={props.loader}
                    handleCancelMagiModal={props.handleCancelMagiModal}
                    isCancelBtn={false}
                />
            ) : null}
            {props?.openOTPLoginForm ?
                <OtpLoginModal
                    open={props.openOTPLoginForm}
                    onClose={props.closeOTPLoginModal}
                    loader={props.loader}
                    generateLoginOTP={props.generateLoginOTP}
                    isInvalidCode={props.isInvalidCode}
                    isValidCode={props.isValidCode}
                    inputOtp={props.inputOtp}
                    userEmail={props.userEmail}
                    verifyLoginOTP={props.verifyLoginOTP}
                    resendOTP={props.resendOTP}
                    setInputOtp={props.setInputOtp}
                />
                : null

            }
            {
                props.openPasswordLoginForm &&
                <PasswordLoginModal
                    open={props.openPasswordLoginForm}
                    onClose={props.closePasswordModal}
                    loader={props.loader}
                    loginWithPasswordForm={props.loginWithPasswordForm}
                    continueWithUniqueCode={props.continueWithUniqueCode}
                />

            }
            {props.mailCheckModalOpen ? (
                <ConfirmMagicLinkModal
                    isClose={false}
                    open={props.mailCheckModalOpen}
                    netSupportRedirect={props.netSupportRedirect}
                />
            ) : null}
            {props?.magicConfirmOpen ? (
                <CancelMagicLinkRequest
                    open={props.magicConfirmOpen}
                    handleClose={props.closeMagicConfirmModal}
                    modalText={`Remove Net Info`}
                    DilogContent={`Are you sure you want to cancel. On canceling net information will be deleted!`}
                    ConfirmBtn={`Yes`}
                />
            ) : null}
            {props.editorMemo?._objects?.length !== 0 && !props.assemblyEditor &&
                <div className={`flex justify-center items-center absolute bottom-0 left-0 w-full border-t-[2.5px] border-[#047AFF]`}>
                    <div className={`text-white  bg-[#047AFF]  min-w-fit min-h-fit rounded-bl rounded-br gap-4 px-6 py-1 z-50 absolute top-0`}>
                        <p className="text-sm text-center">{props.threePointStart ? "Please move the points accordingly" : "Save Each Completed Net Design Before Placing Next Solar Array"}</p>
                    </div>
                </div>}
            {props.addLine &&
                <div className={`flex justify-center items-center absolute bottom-0 left-0 w-full border-t-[2.5px] border-[#047AFF]`}>
                    <div className={`text-white  bg-[#251e1e]  min-w-fit min-h-fit rounded-bl rounded-br gap-4 px-6 py-1 z-50 absolute top-0`}>
                        <p className="text-sm text-center">{props.linePoints?.length === 1 ? "click any support to complete a SUPPORT WIRE" : "click any support to start adding a SUPPORT WIRE"}</p>
                    </div>
                </div>}
        </div>
    );
};

export default Header;